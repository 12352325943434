'use client'

import { useEffect, useState } from 'react'
import Logo from '@crystal-eyes/premeeting/components/Logo'
import MiniLogo from '@crystal-eyes/premeeting/components/MiniLogo'
import Link from 'next/link'
import cn from 'classnames'

export default function NavLogoSection({ expanded }: { expanded: boolean }) {
  const homePath = '/home'
  const [renderLogos, setRenderLogos] = useState<boolean>(false)
  useEffect(() => {
    setRenderLogos(true)
  }, [])

  const shouldRenderCrystalLogo = renderLogos
  return (
    <>
      {!renderLogos && <div className={cn('tw-w-[74px]')}></div>}

      {shouldRenderCrystalLogo && (
        <div className={cn({ 'tw-pl-2': expanded })}>
          <Link href={homePath}>
            {expanded ? (
              <div className={cn('tw-text-white')}>
                <Logo />
              </div>
            ) : (
              <div>
                <MiniLogo
                  className={cn('tw-w-10', 'tw-h-10', 'tw-text-strong')}
                />
              </div>
            )}
          </Link>
        </div>
      )}
    </>
  )
}
