'use client'

import Drawer from '@crystal-eyes/premeeting/components/elements/Drawer'
import Ledger from '@crystal-eyes/premeeting/components/Ledger'
import cn from 'classnames'

interface OwnProps {
  toggleDrawer: () => void
  drawerOpen: boolean
}

export default function LedgerDrawer({ toggleDrawer, drawerOpen }: OwnProps) {
  return (
    <Drawer
      className={cn(
        'ledgerDrawer',
        'tw-flex',
        'tw-flex-col',
        'tw-gap-6',
        'tw-overflow-auto',
        'tw-right-0',
        'tw-m-[15px_15px_0]',
        'tw-shadow-primary',
        'tw-w-[calc(100%-50px)]',
        'lg:tw-w-[calc(100%-378px)]',
        'lg:tw-m-0',
      )}
      isOpen={drawerOpen}
      close={toggleDrawer}
    >
      <Ledger />
    </Drawer>
  )
}
