'use client'

import { useEffect, useState } from 'react'
import styles from './SideNavigationAuthed.module.css'
import { CHROME_INSTALL_URL } from '@premeeting/lib/constants'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import NavTopSection from './NavTopSection'
import { usePathname } from 'next/navigation'
import { NavSection, NavSectionTitle } from './types'
import NavBottomSection from './NavBottomSection'
import NavLogoSection from './NavLogoSection'
import ProfileSubNav from './secondary_navigation/ProfileSubNav'
import SettingsSubNav from './secondary_navigation/SettingsSubNav'
import classNamesBind from 'classnames/bind'

const cn = classNamesBind.bind(styles)

export default function SideNavigationAuthed() {
  const { data: me } = useMeV2()
  const pathName = usePathname()

  const [activeSection, setActiveSection] = useState<NavSectionTitle | null>(
    null,
  )
  const [mobileOpen, setMobileOpen] = useState<boolean>(false)

  const toggleMobileOpen = () => {
    setMobileOpen(!mobileOpen)
  }

  const toggleActiveSection = (sectionTitle: NavSectionTitle) => {
    setActiveSection(activeSection === sectionTitle ? null : sectionTitle)
  }

  const myVanityUrl = me?.identity?.rootSnapshot?.vanityUrl
  const myProfilePath = myVanityUrl
    ? `/p/${myVanityUrl}`
    : `/p/${me?.identity?.id.replace('ident:', '')}`

  const [expanded, setExpanded] = useState<boolean>(true)
  const miniProfileView = !!pathName?.includes('/p/')
  const miniSettingsView = !!pathName?.includes('/settings')
  const miniView = miniProfileView || miniSettingsView

  const navSections: NavSection[] = [
    {
      title: 'Install Chrome Extension',
      path: CHROME_INSTALL_URL,
      items: [],
      icon: Icons.Download,
      external: true,
    },
    {
      title: 'Home',
      path: '/home',
      items: [],
      icon: Icons.Home,
    },
    {
      title: 'My profile',
      path: myProfilePath,
      items: [],
      icon: Icons.Person,
    },
    {
      title: 'People',
      path: '/people',
      items: [],
      icon: Icons.Peers,
    },
  ]

  const checkPathMatch = () => {
    navSections.forEach(section => {
      if (section.path === pathName) {
        setActiveSection(section.title)
      }
      section.items.forEach(item => {
        if (item.path === pathName) {
          setActiveSection(section.title)
        }
      })
    })
  }

  useEffect(() => {
    if (miniView) {
      setExpanded(false)
    } else {
      checkPathMatch()
    }
  }, [me, pathName])

  return (
    <>
      <nav
        className={cn('sideNavBar', {
          mobileOpen: mobileOpen,
          expanded: expanded,
          miniView: miniView,
        })}
        id="crystal_side_navigation"
        onMouseEnter={() => miniView && setExpanded(true)}
        onMouseLeave={() => miniView && setExpanded(false)}
      >
        <div className={cn('mobileHamburger')} onClick={toggleMobileOpen}>
          {mobileOpen ? (
            'x'
          ) : (
            <Icon
              className={cn('tw-w-[1.8em]', 'tw-h-[1.8em]')}
              icon={Icons.Hamburger}
            />
          )}
        </div>
        <div
          className={cn(
            'navigationContainer',
            'tw-p-6',
            'tw-pl-4',
            'tw-h-[calc(100%-48px)]',
            'tw-flex',
            'tw-w-[300px]',
            'tw-flex-col',
            'tw-gap-7',
            'tw-items-start',
            'tw-justify-start',
            {
              mobileOpen: mobileOpen,
            },
          )}
        >
          <NavLogoSection expanded={expanded} />

          <div
            className={cn(
              'tw-flex',
              'tw-flex-col',
              'tw-justify-between',
              'tw-h-full',
              'tw-w-full',
            )}
          >
            <div className={cn('tw-flex', 'tw-flex-col', 'tw-gap-4')}>
              <div className={cn('tw-flex', 'tw-flex-col', 'tw-gap-[1px]')}>
                {navSections.map(section => {
                  return (
                    <NavTopSection
                      key={section.title}
                      section={section}
                      toggleActiveSection={toggleActiveSection}
                      expanded={expanded}
                      external={section.external}
                    />
                  )
                })}
              </div>
            </div>
            <NavBottomSection expanded={expanded} />
          </div>
        </div>
      </nav>
      {miniProfileView && (
        <div
          className={cn(
            'tw-w-[var(--secondary-nav-width)]',
            'tw-ml-[75px]',
            'tw-hidden',
            'lg:tw-block',
          )}
        >
          <ProfileSubNav />
        </div>
      )}
      {miniSettingsView && <SettingsSubNav />}
    </>
  )
}
