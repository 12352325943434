'use client'

import React from 'react'
import Button from '@crystal-eyes/premeeting/components/elements/Button/Button'
import cn from 'classnames'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'

type Props = {
  onClick: () => void
  expanded?: boolean
}

export default function ActivityToken({ onClick, expanded }: Props) {
  return (
    <Button
      theme="secondary"
      onClick={onClick}
      style={{ paddingRight: '0.5rem', paddingLeft: '0.5rem' }}
    >
      <div className={cn('tw-flex', 'tw-items-center', 'tw-justify-center')}>
        <Icon
          icon={Icons.User}
          className={cn(
            'tw-h-6',
            'tw-w-6',
            'tw-text-icon-small',
            'tw-stroke-[2px]',
            {
              'tw-mr-2': expanded,
            },
          )}
        />
        {expanded && (
          <span className={cn('tw-text-primary', 'tw-font-bold')}>
            Activity{' '}
          </span>
        )}
      </div>
    </Button>
  )
}
