import './Tooltip.module.css'
import React from 'react'
import cn from 'classnames'

interface OwnProps {
  children: React.ReactNode
  content: React.ReactNode
  position?: 'top' | 'bottom' | 'bottom-left' | 'left' | 'right'
}

export default function Tooltip({
  children,
  content,
  position = 'top',
}: OwnProps) {
  return (
    <div className={cn('tooltip', `${position}-tooltip`)}>
      {children}
      <div className={cn('tooltip-content', 'tw-whitespace-pre-wrap')}>
        {content}
      </div>
    </div>
  )
}
