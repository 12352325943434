interface OwnProps {
  className?: string
}

export default function MiniLogo({ className }: OwnProps) {
  return (
    <svg
      className={className}
      width="256"
      height="256"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="256" height="256" fill="none" />
      <path
        d="M59.786 219V36.139H138.716C152.288 36.139 164.163 38.8176 174.342 44.1749C184.521 49.5321 192.438 57.0621 198.092 66.7646C203.747 76.4672 206.575 87.8068 206.575 100.783C206.575 113.879 203.658 125.218 197.825 134.802C192.051 144.385 183.925 151.766 173.449 156.945C163.032 162.124 150.859 164.713 136.93 164.713H89.7866V126.141H126.93C132.764 126.141 137.734 125.129 141.841 123.105C146.008 121.022 149.193 118.075 151.395 114.266C153.657 110.456 154.788 105.962 154.788 100.783C154.788 95.545 153.657 91.0806 151.395 87.3901C149.193 83.64 146.008 80.7828 141.841 78.8185C137.734 76.7946 132.764 75.7827 126.93 75.7827H109.43V219H59.786Z"
        fill="currentColor"
      />
      <circle cx="186.907" cy="197.907" r="21.0933" fill="currentColor" />
    </svg>
  )
}
